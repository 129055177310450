export const addCategory = (categoryData) => ({
  type: "ADD_CATEGORY",
  payload: categoryData,
});
export const setListCategory = (categoryData) => ({
  type: "SET_LIST_CATEGORY",
  payload: categoryData,
});
export const removeCategory = (categoryID) => ({
  type: "REMOVE_CATEGORY",
  payload: categoryID,
});
export const updateCategoryInList = (categoryID) => ({
  type: "UPDATE_CATEGORY_IN_LIST",
  payload: categoryID,
});
