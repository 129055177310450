import React from "react";
import { Redirect, Route, Switch } from "react-router-dom";
import asyncComponent from "util/asyncComponent";

const CMM = ({ match }) => (
  <Switch>
    <Route
      path={`${match.url}/editCMM`}
      component={asyncComponent(() => import("./editCMM/index"))}
    />{" "}
    <Route
      path={`${match.url}/editCMMATC`}
      component={asyncComponent(() => import("./editCMMATC/index"))}
    />
    <Route
      path={`${match.url}/editCMMAWC`}
      component={asyncComponent(() => import("./editCMMAWC/index"))}
    />
    <Route
      path={`${match.url}/editCMMBVC`}
      component={asyncComponent(() => import("./editCMMBVC/index"))}
    />
    <Route
      path={`${match.url}/editCMMFCG`}
      component={asyncComponent(() => import("./editCMMFCG/index"))}
    />
    <Route
      path={`${match.url}/editCMMSC`}
      component={asyncComponent(() => import("./editCMMSC/index"))}
    />{" "}
    <Route
      path={`${match.url}/editCMMFC`}
      component={asyncComponent(() => import("./editCMMFC/index"))}
    />
  </Switch>
);

export default CMM;
