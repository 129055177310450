import React from "react";
import { Redirect, Route, Switch } from "react-router-dom";
import asyncComponent from "util/asyncComponent";

const Advertisements = ({ match }) => (
  <Switch>
    <Route
      path={`${match.url}/addAdvertisements`}
      component={asyncComponent(() => import("./addAdvertisements/index"))}
    />
    <Route
      path={`${match.url}/listAdvertisements`}
      component={asyncComponent(() => import("./listAdvertisements/index"))}
    />{" "}
    <Route
      path={`${match.url}/detailAdvertisements/:id`}
      component={asyncComponent(() => import("./detailAdvertisements/index"))}
    />{" "}
    <Route
      path={`${match.url}/editAdvertisements/:id`}
      component={asyncComponent(() => import("./editAdvertisements/index"))}
    />
  </Switch>
);

export default Advertisements;
