import React, { useEffect } from "react";
import { Button, Checkbox, Form, Input, message } from "antd";
import { useDispatch, useSelector } from "react-redux";
import { Link, useHistory } from "react-router-dom";
import { hideMessage, showAuthLoader, userSignIn } from "../appRedux/actions";

import IntlMessages from "util/IntlMessages";
import CircularProgress from "../components/CircularProgress";
import { sendCode } from "../services/sendCodeService";

const ConfirmCode = () => {
  const dispatch = useDispatch();
  const { loader, alertMessage, showMessage, authUser } = useSelector(
    ({ auth }) => auth
  );
  const history = useHistory();

  const onFinish = async (values) => {
    try {
      const response = await sendCode(values.code);
      if (response.status === 200) {
        message.success("Check Code Successfully");
        history.push("/ResetPassword");
        localStorage.setItem("code", response.data.code);
      } else {
        message.error("Send Code Failed");
      }
    } catch (error) {
      message.error("Send Code Failed");
    }
  };
  return (
    <div className="gx-app-login-wrap">
      <div className="gx-app-login-container">
        <div className="gx-app-login-main-content">
          <div className="gx-app-logo-content">
            <div className="gx-app-logo-content-bg"></div>
            <div className="gx-app-logo-wid">
              <h1>
                <IntlMessages id="app.userAuth.sendFormCode" />
              </h1>
            </div>
            <div className="gx-app-logo">
              <img alt="example" src="/assets/images/logo2.png" />
            </div>
          </div>
          <div className="gx-app-login-content">
            <Form
              layout="vertical"
              initialValues={{ remember: true }}
              name="basic"
              onFinish={onFinish}
              className="gx-signin-form gx-form-row0"
            >
              <Form.Item
                label="Code"
                rules={[
                  {
                    required: true,
                    message: "Please input your Code !",
                  },
                ]}
                name="code"
              >
                <Input placeholder="******" />
              </Form.Item>
              <Form.Item>
                <Button type="primary" className="gx-mb-0" htmlType="submit">
                  <IntlMessages id="app.userAuth.sendFormCode" />
                </Button>
              </Form.Item>
            </Form>
          </div>

          {loader ? (
            <div className="gx-loader-view">
              <CircularProgress />
            </div>
          ) : null}
          {showMessage ? message.error("Username or Password incorrect") : null}
        </div>
      </div>
    </div>
  );
};

export default ConfirmCode;
