import React from "react";
import { Route, Switch } from "react-router-dom/cjs/react-router-dom.min";
import asyncComponent from "../../../util/asyncComponent";

const index = ({ match }) => {
  return (
    <Switch>
      <Route
        path={`${match.url}/listCategoryMachine`}
        component={asyncComponent(() => import("./listCategory/index"))}
      />
      <Route
        path={`${match.url}/addCategoryMachine`}
        component={asyncComponent(() => import("./addCategory/index"))}
      />

      <Route
        path={`${match.url}/detailCategoryMachine/:ID`}
        component={asyncComponent(() => import("./detailCategory/index"))}
      />
      <Route
        path={`${match.url}/editCategoryMachine/:ID`}
        component={asyncComponent(() => import("./editCategory/index"))}
      />
    </Switch>
  );
};

export default index;
