import moment from "moment";

const convertStringToBirthday = (birthdayString) => {
  return moment(birthdayString);
};
const INIT_STATE = {
  isLoading: true,
  data: [],
  error: "",
  editedUser: null,
};
const UserReduces = (state = INIT_STATE, action) => {
  switch (action.type) {
    case "updateData": {
      return { ...state };
    }
    case "ADD_USER":
      const newBirthday = convertStringToBirthday(action.payload.birthday);
      const newData2 = {
        key: state.data.length + 1,
        ID: state.data.length + 1,
        name: action.payload.username,
        email: action.payload.email,
        birthday: newBirthday.format("YYYY-MM-DD"),
        password: action.payload.password,
        role: action.payload.role,
      };
      return {
        ...state,
        data: [...state.data, newData2],
      };
    case "EDIT_USER":
      return {
        ...state,
        editedUser: action.payload,
      };
    case "UPDATE_USER":
      const userIndex = state.data.findIndex(
        (x) => x.ID === Number(action.payload.ID)
      );
      state.data[userIndex] = action.payload;
      state.data[userIndex].birthday =
        action.payload.birthday.format("YYYY-MM-DD");

      return {
        ...state,
        editedUser: null,
      };
    case "REMOVE_USER":
      const updatedData = state.data.filter(
        (user) => user.ID !== action.payload
      );
      return {
        ...state,
        data: updatedData,
      };
    case "SET_LIST_USER":
      return {
        ...state,
        data: action.payload,
      };
    case "UPDATE_USER_IN_LIST":
      const listUpdate = state.data.map((user, index) => {
        if (user.ID == action.payload.id) {
          return {
            ...user,
            email: action.payload.email,
            fullname: action.payload.fullname,
            birthday: action.payload.dateofbirth,
            Role: action.payload.usertype,
          };
        } else {
          return user;
        }
      });
      return {
        ...state,
        data: listUpdate,
      };
    default:
      return state;
  }
};

export default UserReduces;
