export const updateData = (userData) => ({
  type: "UPDATE_USER",
  payload: userData,
});

export const addOneData = () => ({
  type: "addOneData",
});

export const addUser = (userData) => ({
  type: "ADD_USER",
  payload: userData,
});

export const editUser = (userData) => ({
  type: "EDIT_USER",
  payload: userData,
});

export const removeUser = (userID) => ({
  type: "REMOVE_USER",
  payload: userID,
});
export const setListUser = (userData) => ({
  type: "SET_LIST_USER",
  payload: userData,
});
export const updateUserInList = (userData) => ({
  type: "UPDATE_USER_IN_LIST",
  payload: userData,
});
