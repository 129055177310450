import axios from "axios";
import { API_URL } from "../constants";

const postData = {
  username: "",
  password: "",
};
const login = (username, password) => {
  postData.username = username;
  postData.password = password;
  return axios.post(`${API_URL}auth/login`, postData);
};
export default login;

export const getUserProfile = (token) => {
  return axios.get(`${API_URL}auth/profile`, {
    headers: {
      Authorization: "Bearer " + token,
    },
  });
};

export const updateUserProfile = (data, token) => {
  return axios.put(`${API_URL}auth/profileUpdate`, data, {
    headers: {
      Authorization: "Bearer " + token,
    },
  });
};
