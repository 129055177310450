const initialState = {
  products: [],
};

const productReducer = (state = initialState, action) => {
  switch (action.type) {
    case "ADD_PRODUCT":
      return {
        ...state,
        products: [...state.products, action.payload],
      };
    case "SET_LIST_PRODUCT":
      return {
        ...state,
        products: action.payload,
      };
    case "REMOVE_PRODUCT":
      const updatedData = state.data.filter(
        (product) => product.ID !== action.payload
      );
      return {
        ...state,
        products: updatedData,
      };
    case "UPDATE_PRODUCT_IN_LIST":
      const listUpdate = state.data.map((product, index) => {
        if (product.ID == action.payload.id) {
          return {
            ...product,
            product_name: action.payload.product_name,
            product_model: action.payload.product_model,
            category_id: action.payload.category_id,
            product_image: action.payload.product_image,
            product_detailed: action.payload.product_detailed,
          };
        } else {
          return product;
        }
      });
      return {
        ...state,
        products: listUpdate,
      };
    default:
      return state;
  }
};
export default productReducer;
