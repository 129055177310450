import React from "react";
import { Route, Switch } from "react-router-dom";
import Widgets from "./Widgets";
import Metrics from "./Metrics";
import Dashboard from "./dashboard";
import Layouts from "./Layouts";
import asyncComponent from "../../util/asyncComponent";
import Banner from "./Banner";
import ProductManagement from "./ProductManagement";
import { useSelector } from "react-redux";
import Slogan from "./Slogan";
import CompanyProfile from "./CompanyProfile";
import CEOAboutManagement from "./CEOAboutManagement";
import LabManagement from "./LabManagement";
import RecruitmentManagement from "./RecruitmentManagement";
import CRAM from "./CRAM";
import CRFCEM from "./CRFCEM";
import CRTM from "./CRTM";
import EzLight from "./EzLight";
import PDF from "./PDF";
import Contact from "./Contact";
import News from "./News";
import Category from "./Category";
import Profile from "./Profile";
import CMM from "./CleanMachineManagement";
import Handy from "./HandyManegement";
import RenTal from "./RentalManagement";
import NewsInformation from "./NewsInformation";

import MachineDetails from "./machineDetails";

import Advertisements from "./Advertisements";
import Resetpassword from "./Resetpassword";

const Main = ({ match }) => {
  const user = useSelector((x) => x.auth.authUser);
  return (
    <Switch>
      {user.usertype === 1 && (
        <Route path={`${match.url}/dashboard`} component={Dashboard} />
      )}
      <Route path={`${match.url}/Banner`} component={Banner} />
      <Route path={`${match.url}/Slogan`} component={Slogan} />
      <Route path={`${match.url}/profile`} component={Profile} />
      <Route path={`${match.url}/product`} component={ProductManagement} />
      <Route path={`${match.url}/CategoryMachine`} component={Category} />

      <Route path={`${match.url}/widgets`} component={Widgets} />
      <Route path={`${match.url}/metrics`} component={Metrics} />
      <Route path={`${match.url}/CMM`} component={CMM} />

      <Route path={`${match.url}/layouts`} component={Layouts} />
      <Route
        path={`${match.url}/CEOAboutManagement`}
        component={CEOAboutManagement}
      />
      <Route path={`${match.url}/CRAM`} component={CRAM} />
      <Route path={`${match.url}/Handy`} component={Handy} />
      <Route path={`${match.url}/RentalManagement`} component={RenTal} />
      <Route path={`${match.url}/Contact`} component={Contact} />
      <Route path={`${match.url}/CRTM`} component={CRTM} />
      <Route path={`${match.url}/EzLight`} component={EzLight} />
      <Route path={`${match.url}/PDF`} component={PDF} />
      <Route path={`${match.url}/CRFCEM`} component={CRFCEM} />
      <Route path={`${match.url}/CompanyProfile`} component={CompanyProfile} />
      <Route path={`${match.url}/LabManagement`} component={LabManagement} />
      <Route path={`${match.url}/MachineDetails`} component={MachineDetails} />
      <Route
        path={`${match.url}/NewsInformation`}
        component={NewsInformation}
      />
      <Route
        path={`${match.url}/RecruitmentManagement`}
        component={RecruitmentManagement}
      />
      <Route path={`${match.url}/Advertisements`} component={Advertisements} />
      <Route path={`${match.url}/Resetpassword`} component={Resetpassword} />
      <Route path={`${match.url}/News`} component={News} />
      <Route
        path={`${match.url}/algolia`}
        component={asyncComponent(() => import("../algolia"))}
      />
    </Switch>
  );
};

export default Main;
