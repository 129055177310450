import React from "react";
import { Route, Switch } from "react-router-dom";
import asyncComponent from "util/asyncComponent";

const MachineDetails = ({ match }) => (
  <Switch>
    <Route
      path={`${match.url}/addMachine`}
      component={asyncComponent(() => import("./addMachine/index"))}
    />
    <Route
      path={`${match.url}/listMachine`}
      component={asyncComponent(() => import("./listMachine/index"))}
    />{" "}
    <Route
      path={`${match.url}/detailMachine/:ID`}
      component={asyncComponent(() => import("./detailMachine/index"))}
    />{" "}
    <Route
      path={`${match.url}/editMachine/:ID`}
      component={asyncComponent(() => import("./editMachine/index"))}
    />
  </Switch>
);

export default MachineDetails;
