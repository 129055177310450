import React from "react";
import { Redirect, Route, Switch } from "react-router-dom";
import asyncComponent from "util/asyncComponent";

const CompanyProfile = ({ match }) => (
  <Switch>
    <Route
      path={`${match.url}/detailCPAM`}
      component={asyncComponent(() => import("./detailCPAM/index"))}
    />{" "}
    <Route
      path={`${match.url}/editCPAM`}
      component={asyncComponent(() => import("./editCPAM/index"))}
    />
  </Switch>
);

export default CompanyProfile;
