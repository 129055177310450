import React from "react";
import { Redirect, Route, Switch } from "react-router-dom";
import asyncComponent from "util/asyncComponent";

const RenTal = ({ match }) => (
  <Switch>
    <Route
      path={`${match.url}/listRental`}
      component={asyncComponent(() => import("./listRental/index"))}
    />{" "}
    <Route
      path={`${match.url}/detailRental/:ID`}
      component={asyncComponent(() => import("./detailRental/index"))}
    />
    <Route
      path={`${match.url}/listInquiry`}
      component={asyncComponent(() => import("./listInquiry/index"))}
    />{" "}
    <Route
      path={`${match.url}/detailInquiry/:ID`}
      component={asyncComponent(() => import("./detailInquiry/index"))}
    />
  </Switch>
);

export default RenTal;
