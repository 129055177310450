const initialState = {
  banners: [],
};

const bannerReducer = (state = initialState, action) => {
  switch (action.type) {
    case "ADD_BANNER":
      return {
        ...state,
        banners: [...state.banners, action.payload],
      };
    case "SET_LIST_BANNER":
      return {
        ...state,
        data: action.payload,
      };
    case "REMOVE_BANNER":
      const updatedData = state.data.filter(
        (banner) => banner.ID !== action.payload
      );
      return {
        ...state,
        data: updatedData,
      };
    case "UPDATE_BANNER_IN_LIST":
      const listUpdate = state.data.map((banner, index) => {
        if (banner.ID == action.payload.id) {
          return {
            ...banner,
            title: action.payload.title,
            content: action.payload.content,
            images: action.payload.images,
            link: action.payload.link,
          };
        } else {
          return banner;
        }
      });
      return {
        ...state,
        data: listUpdate,
      };
    default:
      return state;
  }
};
export default bannerReducer;
