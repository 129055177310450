export const setListProduct = (productData) => ({
  type: "SET_LIST_PRODUCT",
  payload: productData,
});
export const removeProduct = (productID) => ({
  type: "REMOVE_PRODUCT",
  payload: productID,
});
export const addProduct = (productData) => ({
  type: "ADD_PRODUCT",
  payload: productData,
});
export const updateProductInList = (productID) => ({
  type: "UPDATE_PRODUCT_IN_LIST",
  payload: productID,
});
