import axios from "axios";
import { API_URL } from "../constants";

export const sendMail = (email) => {
  const formData = new FormData();
  formData.append("email", email);
  return axios
    .post(`${API_URL}password/email`, formData)
    .then((response) => {
      return response.data;
    })
    .catch((error) => {
      throw error;
    });
};
