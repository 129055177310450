import React from "react";
import { Redirect, Route, Switch } from "react-router-dom";
import asyncComponent from "util/asyncComponent";

const NewsInformation = ({ match }) => (
  <Switch>
    <Route
      path={`${match.url}/listNewsInformation`}
      component={asyncComponent(() => import("./listNewsInformation/index"))}
    />
    <Route
      path={`${match.url}/detailsNewsInformation/:ID`}
      component={asyncComponent(() => import("./detailsNewsInformation/index"))}
    />
    <Route
      path={`${match.url}/addNewsInformation`}
      component={asyncComponent(() => import("./addNewsInformation/index"))}
    />
    <Route
      path={`${match.url}/editNewsInformation/:ID`}
      component={asyncComponent(() => import("./editNewsInformation/index"))}
    />
  </Switch>
);

export default NewsInformation;
