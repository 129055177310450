import React from "react";
import { Redirect, Route, Switch } from "react-router-dom";
import asyncComponent from "util/asyncComponent";

const Slogan = ({ match }) => (
  <Switch>
    <Route
      path={`${match.url}/detailSlogan`}
      component={asyncComponent(() => import("./detailSlogan/index"))}
    />{" "}
    <Route
      path={`${match.url}/editSlogan`}
      component={asyncComponent(() => import("./editSlogan/index"))}
    />
  </Switch>
);

export default Slogan;
