import React from "react";
import { Route, Switch } from "react-router-dom/cjs/react-router-dom.min";
import asyncComponent from "../../../util/asyncComponent";

const index = ({ match }) => {
  return (
    <Switch>
      <Route
        path={`${match.url}/listProduct`}
        component={asyncComponent(() => import("./listProduct/index"))}
      />
      <Route
        path={`${match.url}/addProduct`}
        component={asyncComponent(() => import("./addProduct/index"))}
      />
      <Route
        path={`${match.url}/detailProduct/:ID`}
        component={asyncComponent(() => import("./detailProduct/index"))}
      />
      <Route
        path={`${match.url}/editProduct/:ID`}
        component={asyncComponent(() => import("./editProduct/index"))}
      />
    </Switch>
  );
};

export default index;
