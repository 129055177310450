import React from "react";
import { Redirect, Route, Switch } from "react-router-dom";
import asyncComponent from "util/asyncComponent";

const Handy = ({ match }) => (
  <Switch>
    <Route
      path={`${match.url}/Handy`}
      component={asyncComponent(() => import("./Handy/index"))}
    />{" "}
    <Route
      path={`${match.url}/HandyDCH`}
      component={asyncComponent(() => import("./HandyDCH/index"))}
    />
    <Route
      path={`${match.url}/HandyDCHHolder2`}
      component={asyncComponent(() => import("./HandyDCHHolder2/index"))}
    />
    <Route
      path={`${match.url}/HandyDCHPack`}
      component={asyncComponent(() => import("./HandyDCHPack/index"))}
    />
    <Route
      path={`${match.url}/HandyDCP`}
      component={asyncComponent(() => import("./HandyDCP/index"))}
    />
    <Route
      path={`${match.url}/HandyDCPad`}
      component={asyncComponent(() => import("./HandyDCPad/index"))}
    />{" "}
  </Switch>
);

export default Handy;
