const initialState = {
  slogans: [],
};

const sloganReducer = (state = initialState, action) => {
  switch (action.type) {
    case "ADD_SLOGAN":
      return {
        ...state,
        banners: [...state.slogans, action.payload],
      };
    case "SET_LIST_SLOGAN":
      return {
        ...state,
        data: action.payload,
      };
    case "REMOVE_SLOGAN":
      const updatedData = state.data.filter(
        (slogan) => slogan.ID !== action.payload
      );
      return {
        ...state,
        data: updatedData,
      };
    case "UPDATE_SLOGAN_IN_LIST":
      const listUpdate = state.data.map((slogan, index) => {
        if (slogan.ID == action.payload.id) {
          return {
            ...slogan,

            content: action.payload.content,
          };
        } else {
          return slogan;
        }
      });
      return {
        ...state,
        data: listUpdate,
      };
    default:
      return state;
  }
};
export default sloganReducer;
