import React from "react";
import { Redirect, Route, Switch } from "react-router-dom";
import asyncComponent from "util/asyncComponent";

const News = ({ match }) => (
  <Switch>
    <Route
      path={`${match.url}/listNews`}
      component={asyncComponent(() => import("./listNews/index"))}
    />
    <Route
      path={`${match.url}/detailNews/:ID`}
      component={asyncComponent(() => import("./detailNews/index"))}
    />
    <Route
      path={`${match.url}/addNews`}
      component={asyncComponent(() => import("./addNews/index"))}
    />
    <Route
      path={`${match.url}/editNews/:ID`}
      component={asyncComponent(() => import("./editNews/index"))}
    />
  </Switch>
);

export default News;
