import React from "react";
import { Route, Switch } from "react-router-dom/cjs/react-router-dom.min";
import asyncComponent from "../../../util/asyncComponent";

const Profile = ({ match }) => {
  return (
    <Switch>
      <Route
        path={`${match.url}/getProfile`}
        component={asyncComponent(() => import("./getProfile/index"))}
      />
      <Route
        path={`${match.url}/editProfile/:ID`}
        component={asyncComponent(() => import("./editProfile/index"))}
      />
      <Route
        path={`${match.url}/changepassword`}
        component={asyncComponent(() => import("./changepassword/index"))}
      />
    </Switch>
  );
};

export default Profile;
