import React from "react";
import { Route, Switch } from "react-router-dom/cjs/react-router-dom.min";
import asyncComponent from "../../../util/asyncComponent";

const Resetpassword = ({ match }) => {
  return (
    <Switch>
      <Route
        path={`${match.url}/sendmailform`}
        component={asyncComponent(() => import("./sendMailForm/index"))}
      />
    </Switch>
  );
};

export default Resetpassword;
