export const addSlogan = (sloganData) => ({
  type: "ADD_SLOGAN",
  payload: sloganData,
});
export const setListSlogan = (sloganData) => ({
  type: "SET_LIST_SLOGAN",
  payload: sloganData,
});
export const removeSlogan = (sloganID) => ({
  type: "REMOVE_SLOGAN",
  payload: sloganID,
});
export const updateSloganInList = (sloganID) => ({
  type: "UPDATE_SLOGAN_IN_LIST",
  payload: sloganID,
});
