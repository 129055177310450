import React from "react";
import { Redirect, Route, Switch } from "react-router-dom";
import asyncComponent from "util/asyncComponent";

const Dashboard = ({ match }) => (
  <Switch>
    <Redirect exact from={`${match.url}/`} to={`${match.url}/listMember`} />
    <Route
      path={`${match.url}/listMember`}
      component={asyncComponent(() => import("./listMember/index"))}
    />
    <Route
      path={`${match.url}/detailMember/:ID`}
      component={asyncComponent(() => import("./detailMember/index"))}
    />
    <Route
      path={`${match.url}/addMember`}
      component={asyncComponent(() => import("./addMember/index"))}
    />
    <Route
      path={`${match.url}/editMember/:ID`}
      component={asyncComponent(() => import("./editMember/index"))}
    />
  </Switch>
);

export default Dashboard;
