import React from "react";
import { Redirect, Route, Switch } from "react-router-dom";
import asyncComponent from "util/asyncComponent";

const PDF = ({ match }) => (
  <Switch>
    <Route
      path={`${match.url}/editPDF`}
      component={asyncComponent(() => import("./editPDF/index"))}
    />
    <Route
      path={`${match.url}/uploadPDF`}
      component={asyncComponent(() => import("./uploadPDF/index"))}
    />
    <Route
      path={`${match.url}/listPDF`}
      component={asyncComponent(() => import("./listPDF/index"))}
    />
  </Switch>
);

export default PDF;
