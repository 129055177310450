import axios from "axios";
import { API_URL } from "../constants";

export const confirmNewPassword = (password, password_confirmation, code) => {
  const formData = new FormData();
  formData.append("password", password);
  formData.append("password_confirmation", password_confirmation);
  formData.append("code", code);
  return axios
    .post(`${API_URL}password/reset`, formData)
    .then((response) => {
      return response.data;
    })
    .catch((error) => {
      return error;
    });
};
