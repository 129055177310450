export const addNews = (NewsData) => ({
  type: "ADD_News",
  payload: NewsData,
});
export const setListNews = (NewsData) => ({
  type: "SET_LIST_NEWS",
  payload: NewsData,
});
export const removeNews = (newsID) => ({
  type: "REMOVE_News",
  payload: newsID,
});
export const updateNewsInList = (newsID) => ({
  type: "UPDATE_News_IN_LIST",
  payload: newsID,
});
