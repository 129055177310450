import React, { useEffect } from "react";
import { Button, Checkbox, Form, Input, message } from "antd";
import { useDispatch, useSelector } from "react-redux";
import { Link, useHistory } from "react-router-dom";
import login from "../services/loginService";
import { hideMessage, showAuthLoader, userSignIn } from "../appRedux/actions";

import IntlMessages from "util/IntlMessages";
import CircularProgress from "../components/CircularProgress";
import { sendMail } from "../services/sendMailService";
import { confirmNewPassword } from "../services/confirmNewPassword";

const ResetPass = () => {
  const dispatch = useDispatch();
  const { loader, alertMessage, showMessage, authUser } = useSelector(
    ({ auth }) => auth
  );
  const history = useHistory();

  useEffect(() => {
    if (showMessage) {
      setTimeout(() => {
        dispatch(hideMessage());
      }, 100);
    }
    if (authUser !== null) {
      history.push("/");
    }
  });

  const onFinishFailed = (errorInfo) => {};
  const onFinish = async (values) => {
    const codeReset = localStorage.getItem("code");
    await confirmNewPassword(
      values.password,
      values.confirm_password,
      codeReset
    )
      .then(() => {
        message.success("Reset Password Successfully");
        localStorage.removeItem("code");
        history.push("/SignIn");
      })
      .catch(() => {
        message.error("Resetpassword Fail");
        localStorage.removeItem("code");
      });
  };

  return (
    <div className="gx-app-login-wrap">
      <div className="gx-app-login-container">
        <div className="gx-app-login-main-content">
          <div className="gx-app-logo-content">
            <div className="gx-app-logo-content-bg"></div>
            <div className="gx-app-logo-wid">
              <h1>
                <IntlMessages id="app.userAuth.resetPass" />
              </h1>
            </div>
            <div className="gx-app-logo">
              <img alt="example" src="/assets/images/logo2.png" />
            </div>
          </div>
          <div className="gx-app-login-content">
            <Form
              layout="vertical"
              initialValues={{ remember: true }}
              name="basic"
              onFinish={onFinish}
              onFinishFailed={onFinishFailed}
              className="gx-signin-form gx-form-row0"
            >
              <Form.Item
                label="Password"
                rules={[
                  {
                    required: true,
                    message: "Please input your new Password!",
                  },
                  {
                    min: 6,
                    message: "Password must be at least 6 characters long!",
                  },
                ]}
                name="password"
              >
                <Input.Password placeholder="password" />
              </Form.Item>
              <Form.Item
                label="Confirm Password"
                rules={[
                  {
                    required: true,
                    message: "Please confirm your password!",
                  },
                  {
                    min: 6,
                    message: "Password must be at least 6 characters long!",
                  },
                  ({ getFieldValue }) => ({
                    validator(_, value) {
                      if (!value || getFieldValue("password") === value) {
                        return Promise.resolve();
                      }
                      return Promise.reject(
                        new Error(
                          "The two passwords that you entered do not match!"
                        )
                      );
                    },
                  }),
                ]}
                name="confirm_password"
              >
                <Input.Password placeholder="Confirm-password" />
              </Form.Item>
              <Form.Item>
                <Button type="primary" className="gx-mb-0" htmlType="submit">
                  <IntlMessages id="app.userAuth.sendMail" />
                </Button>
              </Form.Item>
            </Form>
          </div>

          {loader ? (
            <div className="gx-loader-view">
              <CircularProgress />
            </div>
          ) : null}
          {showMessage ? message.error("Username or Password incorrect") : null}
        </div>
      </div>
    </div>
  );
};

export default ResetPass;
