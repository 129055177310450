import { combineReducers } from "redux";
import { connectRouter } from "connected-react-router";
import Settings from "./Settings";
import Auth from "./Auth";
import Notes from "./Notes";
import Contact from "./Contact";
import Common from "./Common";
import User from "./User";
import Banner from "./Banner";
import Slogan from "./Slogan";
import News from "./News";
import Product from "./Product";
import Category from "./Category";
const createRootReducer = (history) =>
  combineReducers({
    router: connectRouter(history),
    settings: Settings,
    auth: Auth,
    notes: Notes,
    contact: Contact,
    common: Common,
    user: User,
    banner: Banner,
    slogan: Slogan,
    news: News,
    product: Product,
    category: Category,
  });

export default createRootReducer;
