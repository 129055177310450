import React, { useEffect } from "react";
import { Button, Checkbox, Form, Input, message } from "antd";
import { useDispatch, useSelector } from "react-redux";
import { Link, useHistory } from "react-router-dom";
import { hideMessage, showAuthLoader, userSignIn } from "../appRedux/actions";
import IntlMessages from "util/IntlMessages";
import CircularProgress from "../components/CircularProgress";
import { sendMail } from "../services/sendMailService";
const ConfirmEmail = () => {
  const dispatch = useDispatch();
  const { loader, alertMessage, showMessage, authUser } = useSelector(
    ({ auth }) => auth
  );
  const history = useHistory();
  useEffect(() => {
    if (showMessage) {
      setTimeout(() => {
        dispatch(hideMessage());
      }, 100);
    }
    if (authUser !== null) {
      history.push("/");
    }
  });

  const onFinishFailed = (errorInfo) => {};

  const onFinish = async (values) => {
    await sendMail(values.email)
      .then((response) => {
        message.success("We have sent you a code to your email");
        history.push("/confirmCode");
      })
      .catch((error) => {
        message.error("Mail Not Found");
      });
  };

  return (
    <div className="gx-app-login-wrap">
      <div className="gx-app-login-container">
        <div className="gx-app-login-main-content">
          <div className="gx-app-logo-content">
            <div className="gx-app-logo-content-bg"></div>
            <div className="gx-app-logo-wid">
              <h1>
                <IntlMessages id="app.userAuth.sendFormMail" />
              </h1>
            </div>
            <div className="gx-app-logo">
              <img alt="example" src="/assets/images/logo2.png" />
            </div>
          </div>
          <div className="gx-app-login-content">
            <Form
              layout="vertical"
              initialValues={{ remember: true }}
              name="basic"
              onFinish={onFinish}
              onFinishFailed={onFinishFailed}
              className="gx-signin-form gx-form-row0"
            >
              <Form.Item
                label="Email"
                rules={[
                  {
                    required: true,
                    message: "Please input your Email !",
                  },
                ]}
                name="email"
              >
                <Input type="email" placeholder="example@gmail.com" />
              </Form.Item>
              <Form.Item>
                <Button type="primary" className="gx-mb-0" htmlType="submit">
                  <IntlMessages id="app.userAuth.sendMail" />
                </Button>
              </Form.Item>
            </Form>
          </div>

          {loader ? (
            <div className="gx-loader-view">
              <CircularProgress />
            </div>
          ) : null}
          {showMessage ? message.error("Username or Password incorrect") : null}
        </div>
      </div>
    </div>
  );
};

export default ConfirmEmail;
