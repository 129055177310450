const initialState = {
  categories: [],
};

const categoryReducer = (state = initialState, action) => {
  switch (action.type) {
    case "ADD_CATEGORY":
      return {
        ...state,
        categories: [...state.categories, action.payload],
      };
    case "SET_LIST_CATEGORY":
      return {
        ...state,
        data: action.payload,
      };
    case "REMOVE_CATEGORY":
      const updatedData = state.data.filter(
        (category) => category.ID !== action.payload
      );
      return {
        ...state,
        data: updatedData,
      };
    case "UPDATE_CATEGORY_IN_LIST":
      const listUpdate = state.data.map((category, index) => {
        if (category.ID == action.payload.id) {
          return {
            ...category,
            name: action.payload.name,
          };
        } else {
          return category;
        }
      });
      return {
        ...state,
        data: listUpdate,
      };
    default:
      return state;
  }
};

export default categoryReducer;
