import React from "react";
import { Menu } from "antd";
import { Link } from "react-router-dom";

import CustomScrollbars from "util/CustomScrollbars";
import SidebarLogo from "./SidebarLogo";
import UserProfile from "./UserProfile";
import AppsNavigation from "./AppsNavigation";
import {
  NAV_STYLE_NO_HEADER_EXPANDED_SIDEBAR,
  NAV_STYLE_NO_HEADER_MINI_SIDEBAR,
  THEME_TYPE_LITE,
} from "../../constants/ThemeSetting";
import IntlMessages from "../../util/IntlMessages";
import { useSelector } from "react-redux";

const SubMenu = Menu.SubMenu;
const MenuItemGroup = Menu.ItemGroup;

const SidebarContent = ({ sidebarCollapsed, setSidebarCollapsed }) => {
  const { navStyle, themeType } = useSelector(({ settings }) => settings);
  const pathname = useSelector(({ common }) => common.pathname);
  const user = useSelector((x) => x.auth.authUser);
  const getNoHeaderClass = (navStyle) => {
    if (
      navStyle === NAV_STYLE_NO_HEADER_MINI_SIDEBAR ||
      navStyle === NAV_STYLE_NO_HEADER_EXPANDED_SIDEBAR
    ) {
      return "gx-no-header-notifications";
    }
    return "";
  };
  const getNavStyleSubMenuClass = (navStyle) => {
    if (navStyle === NAV_STYLE_NO_HEADER_MINI_SIDEBAR) {
      return "gx-no-header-submenu-popup";
    }
    return "";
  };

  const selectedKeys = pathname.substr(1);
  const defaultOpenKeys = selectedKeys.split("/")[1];

  return (
    <>
      <SidebarLogo
        sidebarCollapsed={sidebarCollapsed}
        setSidebarCollapsed={setSidebarCollapsed}
      />
      <div className="gx-sidebar-content">
        <div
          className={`gx-sidebar-notifications ${getNoHeaderClass(navStyle)}`}
        >
          <UserProfile />
          <AppsNavigation />
        </div>
        <CustomScrollbars className="gx-layout-sider-scrollbar">
          <Menu
            defaultOpenKeys={[defaultOpenKeys]}
            selectedKeys={[selectedKeys]}
            theme={themeType === THEME_TYPE_LITE ? "lite" : "dark"}
            mode="inline"
          >
            <MenuItemGroup key="main" className="gx-menu-group">
              {/* Member management */}
              {user.usertype === 1 && (
                <SubMenu
                  key="dashboard"
                  popupClassName={getNavStyleSubMenuClass(navStyle)}
                  title={
                    <span>
                      <span>
                        <IntlMessages id="sidebar.member.management" />
                      </span>
                    </span>
                  }
                >
                  <Menu.Item key="main/dashboard/listMember">
                    <Link to="/main/dashboard/listMember">
                      <span>
                        <IntlMessages id="sidebar.member.management.listMember" />
                      </span>
                    </Link>
                  </Menu.Item>
                  <Menu.Item key="main/dashboard/addMember">
                    <Link to="/main/dashboard/addMember">
                      <span>
                        <IntlMessages id="sidebar.member.management.addMember" />
                      </span>
                    </Link>
                  </Menu.Item>
                </SubMenu>
              )}

              {/* Banner management */}
              <SubMenu
                key="Banner"
                popupClassName={getNavStyleSubMenuClass(navStyle)}
                title={
                  <span>
                    <span>
                      <IntlMessages id="sidebar.banner.management" />
                    </span>
                  </span>
                }
              >
                <Menu.Item key="main/banner/listBanner">
                  <Link to="/main/banner/listBanner">
                    <span>
                      <IntlMessages id="sidebar.banner.management.listBanner" />
                    </span>
                  </Link>
                </Menu.Item>

                <Menu.Item key="main/banner/addBanner">
                  <Link to="/main/banner/addBanner">
                    <span>
                      <IntlMessages id="sidebar.banner.management.addBanner" />
                    </span>
                  </Link>
                </Menu.Item>
              </SubMenu>
              <SubMenu
                key="machineDetails"
                popupClassName={getNavStyleSubMenuClass(navStyle)}
                title={
                  <span>
                    <span>
                      <IntlMessages id="sidebar.machineDetails.management" />
                    </span>
                  </span>
                }
              >
                <Menu.Item key="main/machineDetails/listMachine">
                  <Link to="/main/machineDetails/listMachine">
                    <span>
                      <IntlMessages id="sidebar.machineDetails.management.listMachine" />
                    </span>
                  </Link>
                </Menu.Item>

                <Menu.Item key="main/machineDetails/addMachine">
                  <Link to="/main/machineDetails/addMachine">
                    <span>
                      <IntlMessages id="sidebar.machineDetails.management.addMachine" />
                    </span>
                  </Link>
                </Menu.Item>
              </SubMenu>

              {/* Advertisements management */}
              <SubMenu
                key="Advertisements"
                popupClassName={getNavStyleSubMenuClass(navStyle)}
                title={
                  <span>
                    <span>
                      <IntlMessages id="sidebar.Advertisements.management" />
                    </span>
                  </span>
                }
              >
                <Menu.Item key="main/Advertisements/listAdvertisements">
                  <Link to="/main/Advertisements/listAdvertisements">
                    <span>
                      <IntlMessages id="sidebar.Advertisements.management.listAdvertisements" />
                    </span>
                  </Link>
                </Menu.Item>

                <Menu.Item key="main/Advertisements/addAdvertisements">
                  <Link to="/main/Advertisements/addAdvertisements">
                    <span>
                      <IntlMessages id="sidebar.Advertisements.management.addAdvertisements" />
                    </span>
                  </Link>
                </Menu.Item>
              </SubMenu>
              {/* SloganManager */}
              <SubMenu
                key="Slogan"
                popupClassName={getNavStyleSubMenuClass(navStyle)}
                title={
                  <span>
                    <span>
                      <IntlMessages id="sidebar.slogan.management" />
                    </span>
                  </span>
                }
              >
                <Menu.Item key="main/slogan/detailSlogan">
                  <Link to="/main/slogan/detailSlogan">
                    <span>
                      <IntlMessages id="sidebar.slogan.management.detailSlogan" />
                    </span>
                  </Link>
                </Menu.Item>
              </SubMenu>

              {/* Product manager  */}
              <SubMenu
                key="Product"
                popupClassName={getNavStyleSubMenuClass(navStyle)}
                title={
                  <span>
                    <span>
                      <IntlMessages id="sidebar.product.management" />
                    </span>
                  </span>
                }
              >
                <Menu.Item key="main/product/listProduct">
                  <Link to="/main/product/listProduct">
                    <span>
                      <IntlMessages id="sidebar.product.management.listProduct" />
                    </span>
                  </Link>
                </Menu.Item>

                <Menu.Item key="main/product/addProduct">
                  <Link to="/main/product/addProduct">
                    <span>
                      <IntlMessages id="sidebar.product.management.addProduct" />
                    </span>
                  </Link>
                </Menu.Item>
              </SubMenu>
              {/* News management */}
              <SubMenu
                key="News"
                popupClassName={getNavStyleSubMenuClass(navStyle)}
                title={
                  <span>
                    <span>
                      <IntlMessages id="sidebar.news.management" />
                    </span>
                  </span>
                }
              >
                <Menu.Item key="main/news/listNews">
                  <Link to="/main/news/listNews">
                    <span>
                      <IntlMessages id="sidebar.news.management.listNews" />
                    </span>
                  </Link>
                </Menu.Item>
                <Menu.Item key="main/news/addNews">
                  <Link to="/main/news/addNews">
                    <span>
                      <IntlMessages id="sidebar.news.management.addNews" />
                    </span>
                  </Link>
                </Menu.Item>{" "}
              </SubMenu>
              {/* News information management */}

              <SubMenu
                key="NewsInformation"
                popupClassName={getNavStyleSubMenuClass(navStyle)}
                title={
                  <span>
                    <span>
                      <IntlMessages id="sidebar.NewsInformation.management" />
                    </span>
                  </span>
                }
              >
                <Menu.Item key="main/NewsInformation/listNewsInformation">
                  <Link to="/main/NewsInformation/listNewsInformation">
                    <span>
                      <IntlMessages id="sidebar.NewsInformation.management.listNewsInformation" />
                    </span>
                  </Link>
                </Menu.Item>
                <Menu.Item key="main/NewsInformation/addNewsInformation">
                  <Link to="/main/NewsInformation/addNewsInformation">
                    <span>
                      <IntlMessages id="sidebar.NewsInformation.management.addNewsInformation" />
                    </span>
                  </Link>
                </Menu.Item>{" "}
              </SubMenu>
              {/* Contact management */}
              <SubMenu
                key="RentalManagement"
                popupClassName={getNavStyleSubMenuClass(navStyle)}
                title={
                  <span>
                    <span>
                      <IntlMessages id="sidebar.rental.management" />
                    </span>
                  </span>
                }
              >
                <Menu.Item key="main/RentalManagement/listRental">
                  <Link to="/main/RentalManagement/listRental">
                    <span>
                      <IntlMessages id="sidebar.rental.management.listRental" />
                    </span>
                  </Link>
                </Menu.Item>{" "}
                <Menu.Item key="main/RentalManagement/listInquiry">
                  <Link to="/main/RentalManagement/listInquiry">
                    <span>
                      <IntlMessages id="sidebar.rental.management.listInquiry" />
                    </span>
                  </Link>
                </Menu.Item>{" "}
              </SubMenu>
              {/* PDF management */}
              <SubMenu
                key="PDF"
                popupClassName={getNavStyleSubMenuClass(navStyle)}
                title={
                  <span>
                    <span>
                      <IntlMessages id="sidebar.PDF.management" />
                    </span>
                  </span>
                }
              >
                <Menu.Item key="main/PDF/editPDF">
                  <Link to="/main/PDF/editPDF">
                    <span>
                      <IntlMessages id="sidebar.PDF.management.editPDF" />
                    </span>
                  </Link>
                </Menu.Item>{" "}
                <Menu.Item key="main/PDF/UploadPDF">
                  <Link to="/main/PDF/UploadPDF">
                    <span>
                      <IntlMessages id="sidebar.PDF.management.uploadPDF" />
                    </span>
                  </Link>
                </Menu.Item>{" "}
                <Menu.Item key="main/PDF/listPDF">
                  <Link to="/main/PDF/listPDF">
                    <span>
                      <IntlMessages id="sidebar.PDF.management.listPDF" />
                    </span>
                  </Link>
                </Menu.Item>{" "}
              </SubMenu>
              {/* Category Machine Management */}
              <SubMenu
                key="CategoryMachine"
                popupClassName={getNavStyleSubMenuClass(navStyle)}
                title={
                  <span>
                    <span>
                      <IntlMessages id="sidebar.CategoryMachine.management" />
                    </span>
                  </span>
                }
              >
                <Menu.Item key="main/CategoryMachine/listCategoryMachine">
                  <Link to="/main/CategoryMachine/listCategoryMachine">
                    <span>
                      <IntlMessages id="sidebar.CategoryMachine.management.listCategoryMachine" />
                    </span>
                  </Link>
                </Menu.Item>
                <Menu.Item key="main/CategoryMachine/addCategoryMachine">
                  <Link to="/main/CategoryMachine/addCategoryMachine">
                    <span>
                      <IntlMessages id="sidebar.CategoryMachine.management.addCategoryMachine" />
                    </span>
                  </Link>
                </Menu.Item>{" "}
              </SubMenu>
              {/* Clean Machine Manager  */}
              <SubMenu
                key="CleanMachineManager"
                popupClassName={getNavStyleSubMenuClass(navStyle)}
                title={
                  <span>
                    <span>
                      <IntlMessages id="sidebar.CMM.management" />
                    </span>
                  </span>
                }
              >
                <Menu.Item key="main/CMM/editCMM">
                  <Link to="/main/CMM/editCMM">
                    <span>
                      <IntlMessages id="sidebar.CMM.management.editCMM" />
                    </span>
                  </Link>
                </Menu.Item>{" "}
                <Menu.Item key="main/CMM/editCMMFCG">
                  <Link to="/main/CMM/editCMMFCG">
                    <span>
                      <IntlMessages id="sidebar.CMM.management.editCMM.fcg" />
                    </span>
                  </Link>
                </Menu.Item>{" "}
                <Menu.Item key="main/CMM/editCMMATC">
                  <Link to="/main/CMM/editCMMATC">
                    <span>
                      <IntlMessages id="sidebar.CMM.management.editCMM.atc" />
                    </span>
                  </Link>
                </Menu.Item>{" "}
                <Menu.Item key="main/CMM/editCMMFC">
                  <Link to="/main/CMM/editCMMFC">
                    <span>
                      <IntlMessages id="sidebar.CMM.management.editCMM.fc" />
                    </span>
                  </Link>
                </Menu.Item>{" "}
                <Menu.Item key="main/CMM/editCMMSC">
                  <Link to="/main/CMM/editCMMSC">
                    <span>
                      <IntlMessages id="sidebar.CMM.management.editCMM.sc" />
                    </span>
                  </Link>
                </Menu.Item>{" "}
                <Menu.Item key="main/CMM/editCMMBVC">
                  <Link to="/main/CMM/editCMMBVC">
                    <span>
                      <IntlMessages id="sidebar.CMM.management.editCMM.bvc" />
                    </span>
                  </Link>
                </Menu.Item>{" "}
                <Menu.Item key="main/CMM/editCMMAWC">
                  <Link to="/main/CMM/editCMMAWC">
                    <span>
                      <IntlMessages id="sidebar.CMM.management.editCMM.awc" />
                    </span>
                  </Link>
                </Menu.Item>{" "}
              </SubMenu>
              {/* EZ-Light Management */}

              <SubMenu
                key="EZ-LightManagement"
                popupClassName={getNavStyleSubMenuClass(navStyle)}
                title={
                  <span>
                    <span>
                      <IntlMessages id="sidebar.EZ-Light.management" />
                    </span>
                  </span>
                }
              >
                <Menu.Item key="main/EZ-Light/editEZLight">
                  <Link to="/main/EZLight/editEZLight">
                    <span>
                      <IntlMessages id="sidebar.EZ-Light.management.editEZ-Light" />
                    </span>
                  </Link>
                </Menu.Item>{" "}
              </SubMenu>
              {/* Contact Management */}
              {/* <SubMenu
                key="ContactManagement"
                popupClassName={getNavStyleSubMenuClass(navStyle)}
                title={
                  <span>
                    <span>
                      <IntlMessages id="sidebar.Contact.management" />
                    </span>
                  </span>
                }
              >
                <Menu.Item key="main/Contact/editContact">
                  <Link to="/main/Contact/editContact">
                    <span>
                      <IntlMessages id="sidebar.Contact.management.editContact" />
                    </span>
                  </Link>
                </Menu.Item>{" "}
              </SubMenu> */}
              {/* Handy Management */}
              <SubMenu
                key="HandyManagement"
                popupClassName={getNavStyleSubMenuClass(navStyle)}
                title={
                  <span>
                    <span>
                      <IntlMessages id="sidebar.Handy.management" />
                    </span>
                  </span>
                }
              >
                <Menu.Item key="main/Handy/Handy">
                  <Link to="/main/Handy/Handy">
                    <span>
                      <IntlMessages id="sidebar.Handy.management.Handy" />
                    </span>
                  </Link>
                </Menu.Item>
                <Menu.Item key="main/Handy/HandyDCH">
                  <Link to="/main/Handy/HandyDCH">
                    <span>
                      <IntlMessages id="sidebar.Handy.management.HandyDCH" />
                    </span>
                  </Link>
                </Menu.Item>
                <Menu.Item key="main/Handy/HandyDCHPack">
                  <Link to="/main/Handy/HandyDCHPack">
                    <span>
                      <IntlMessages id="sidebar.Handy.management.HandyDCHPack" />
                    </span>
                  </Link>
                </Menu.Item>
                <Menu.Item key="main/Handy/HandyDCPad">
                  <Link to="/main/Handy/HandyDCPad">
                    <span>
                      <IntlMessages id="sidebar.Handy.management.HandyDCPad" />
                    </span>
                  </Link>
                </Menu.Item>
                <Menu.Item key="main/Handy/HandyDCHHolder2">
                  <Link to="/main/Handy/HandyDCHHolder2">
                    <span>
                      <IntlMessages id="sidebar.Handy.management.HandyDCHHolder2" />
                    </span>
                  </Link>
                </Menu.Item>{" "}
                <Menu.Item key="main/Handy/HandyDCP">
                  <Link to="/main/Handy/HandyDCP">
                    <span>
                      <IntlMessages id="sidebar.Handy.management.HandyDCP" />
                    </span>
                  </Link>
                </Menu.Item>
              </SubMenu>
              {/* Clean roller/adhesive tape Management */}
              <SubMenu
                key="CleanRollerTapeManagement"
                popupClassName={getNavStyleSubMenuClass(navStyle)}
                title={
                  <span>
                    <span>
                      <IntlMessages id="sidebar.CRTM.management" />
                    </span>
                  </span>
                }
              >
                <Menu.Item key="main/CRTM/editCRTM">
                  <Link to="/main/CRTM/editCRTM">
                    <span>
                      <IntlMessages id="sidebar.CRTM.management.editCRTM" />
                    </span>
                  </Link>
                </Menu.Item>
              </SubMenu>
              {/* Clean roller for clothing etc Management */}
              <SubMenu
                key="CleanRollerForClothingEtcManagement"
                popupClassName={getNavStyleSubMenuClass(navStyle)}
                title={
                  <span>
                    <span>
                      <IntlMessages id="sidebar.CRFCEM.management" />
                    </span>
                  </span>
                }
              >
                <Menu.Item key="main/CRFCEM/editCRFCEM">
                  <Link to="/main/CRFCEM/editCRFCEM">
                    <span>
                      <IntlMessages id="sidebar.CRFCEM.management.editCRFCEM" />
                    </span>
                  </Link>
                </Menu.Item>
              </SubMenu>
              {/* Clean roller about Management */}
              <SubMenu
                key="CleanRollerAboutManagement"
                popupClassName={getNavStyleSubMenuClass(navStyle)}
                title={
                  <span>
                    <span>
                      <IntlMessages id="sidebar.CRAM.management" />
                    </span>
                  </span>
                }
              >
                <Menu.Item key="main/CRAM/editCRAM">
                  <Link to="/main/CRAM/editCRAM">
                    <span>
                      <IntlMessages id="sidebar.CRAM.management.editCRAM" />
                    </span>
                  </Link>
                </Menu.Item>
              </SubMenu>
              {/* Lab Management */}
              <SubMenu
                key="LabManagement"
                popupClassName={getNavStyleSubMenuClass(navStyle)}
                title={
                  <span>
                    <span>
                      <IntlMessages id="sidebar.LabManagement.management" />
                    </span>
                  </span>
                }
              >
                <Menu.Item key="main/LabManagement/editLab">
                  <Link to="/main/LabManagement/editLab">
                    <span>
                      <IntlMessages id="sidebar.Lab.management.editLab" />
                    </span>
                  </Link>
                </Menu.Item>
              </SubMenu>
              {/* Company profile/Access Management */}
              <SubMenu
                key="CompanyProfileAccessManagement"
                popupClassName={getNavStyleSubMenuClass(navStyle)}
                title={
                  <span>
                    <span>
                      <IntlMessages id="sidebar.CompanyProfile.management" />
                    </span>
                  </span>
                }
              >
                <Menu.Item key="main/CompanyProfile/editCPAM">
                  <Link to="/main/CompanyProfile/editCPAM">
                    <span>
                      <IntlMessages id="sidebar.CPAM.management.editCPAM" />
                    </span>
                  </Link>
                </Menu.Item>
              </SubMenu>
              {/* CEO About Management */}
              <SubMenu
                key="CEOAboutManagement "
                popupClassName={getNavStyleSubMenuClass(navStyle)}
                title={
                  <span>
                    <span>
                      <IntlMessages id="sidebar.CEOAboutManagement.management" />
                    </span>
                  </span>
                }
              >
                <Menu.Item key="main/CEOAboutManagement/editCEOAbout">
                  <Link to="/main/CEOAboutManagement/editCEOAbout">
                    <span>
                      <IntlMessages id="sidebar.CEOAbout.management.editCEOAbout" />
                    </span>
                  </Link>
                </Menu.Item>
              </SubMenu>

              {/* Recruitment Management */}
              <SubMenu
                key="RecruitmentManagement "
                popupClassName={getNavStyleSubMenuClass(navStyle)}
                title={
                  <span>
                    <span>
                      <IntlMessages id="sidebar.RecruitmentManagement.management" />
                    </span>
                  </span>
                }
              >
                <Menu.Item key="main/RecruitmentManagement/editRecruitment">
                  <Link to="/main/RecruitmentManagement/editRecruitment">
                    <span>
                      <IntlMessages id="sidebar.Recruitment.management.editRecruitment" />
                    </span>
                  </Link>
                </Menu.Item>
              </SubMenu>
            </MenuItemGroup>
          </Menu>
        </CustomScrollbars>
      </div>
    </>
  );
};

export default React.memo(SidebarContent);
