export const addBanner = (bannerData) => ({
  type: "ADD_BANNER",
  payload: bannerData,
});
export const setListBanner = (bannerData) => ({
  type: "SET_LIST_BANNER",
  payload: bannerData,
});
export const removeBanner = (bannerID) => ({
  type: "REMOVE_BANNER",
  payload: bannerID,
});
export const updateBannerInList = (bannerID) => ({
  type: "UPDATE_BANNER_IN_LIST",
  payload: bannerID,
});
