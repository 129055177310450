import React, { useState, useEffect } from "react";
import { useDispatch } from "react-redux";
import { Avatar, Popover } from "antd";
import { userSignOut } from "appRedux/actions/Auth";
import { useHistory } from "react-router-dom/cjs/react-router-dom.min";
import { getUserProfile } from "../../services/loginService";
import { Backend_URL } from "../../constants";

const UserProfile = () => {
  const dispatch = useDispatch();
  const history = useHistory();
  const [profile, setProfile] = useState();
  const token = localStorage.getItem("token");
  useEffect(async () => {
    const result = await getUserProfile(token);
    setProfile(result.data.original.data);
  }, []);
  const userMenuOptions = (
    <ul className="gx-user-popover">
      <li onClick={() => history.replace("/main/profile/getProfile")}>
        My Account
      </li>
      <li onClick={() => dispatch(userSignOut())}>Logout</li>
    </ul>
  );

  return (
    <div className="gx-flex-row gx-align-items-center gx-mb-4 gx-avatar-row">
      <Popover
        placement="bottomRight"
        content={userMenuOptions}
        trigger="click"
      >
        <Avatar
          src={`${Backend_URL}${profile?.avatar_image}`}
          className="gx-size-40 gx-pointer gx-mr-3"
          alt=""
        ></Avatar>
        <span className="gx-avatar-name">
          {profile?.username}
          <i className="icon icon-chevron-down gx-fs-xxs gx-ml-2" />
        </span>
      </Popover>
    </div>
  );
};

export default UserProfile;
