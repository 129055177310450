const initialState = {
  news: [],
};

const newsReducer = (state = initialState, action) => {
  switch (action.type) {
    case "ADD_NEWS":
      return {
        ...state,
        news: [...state.news, action.payload],
      };
    case "SET_LIST_NEWS":
      return {
        ...state,
        data: action.payload,
      };
    case "REMOVE_NEWS":
      const updatedData = state.data.filter(
        (news) => news.ID !== action.payload
      );
      return {
        ...state,
        data: updatedData,
      };
    case "UPDATE_NEWS_IN_LIST":
      const listUpdate = state.data.map((news, index) => {
        if (news.ID == action.payload.id) {
          return {
            ...news,
            title: action.payload.title,
            content: action.payload.content,
            images: action.payload.images,
          };
        } else {
          return news;
        }
      });
      return {
        ...state,
        data: listUpdate,
      };
    default:
      return state;
  }
};
export default newsReducer;
